
import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis py-0 mb-0">
        <div className='gray-bg'>
        <div className="container">
        <h2 className="heading-h2 text-center"><span className="heading-h3 bluecolor">What is</span>
                White Label NFT Marketplace?</h2>
                <div className="text-center">
                <p className="pharagraph text-center" >White Label NFT Marketplace is the pre-build solution to create an NFT marketplace instantly. By far, one of the best methods to start your NFT Marketplace business in a cost-efficient manner. By adopting our premium white label services, you will have the luxury to customize and modify the features and functionalities according to your business requirements. With several ways to make revenue, NFT Marketplace development ranks among the top business ideas for entrepreneurs.
              </p>
              <p className="pharagraph text-center mb-0" >White label services mean that the script or code is already pre-developed and it cuts down the development time considerably. Our readymade, secure, and feature-rich NFT Marketplace has been developed by experienced blockchain experts and is designed especially for startups. We provide 24*7 customer support along with the NFT Marketplace service to ensure quality-driven results.
              </p>
                </div>
        </div>
        </div>
        </section>
    )
  }
}

export default Whatis