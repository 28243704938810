import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



class Invaluable extends React.Component {


  render() {

    return (
      <div className='pt-100'>
      <section className="usecase bgremove p-0 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className="heading-h3 bluecolor">Our Invaluable</span> White Label NFT Marketplace Development Services</h3>
              <p>Apart from the NFT Marketplace that we provide, we focus on engaging our clients with other services related to it as well. By connecting with us, you will be entitled to more than one business service. </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph" ><b>Consultant services -</b> We provide valuable consultancy services related to white label NFT Marketplace. Whether you have important queries related to it or you want to develop and deploy your own tailored NFT Marketplace, our experts can get the job done.
              </p>
              <p className="pharagraph" ><b>Perfect Smart Contract  -</b> As a top white label service provider, our experts maintain high standards while preparing smart contracts for NFT Marketplace development. We conduct audits and ensure a bug-free, customized solution is presented to our clients.
              </p>
              <p className="pharagraph" ><b>24/7 Technical Support -</b> Our eminent developers take responsibility for the maintenance of the NFT Marketplace from time to time. We also bring in 24/7 support as a service for our clients once the project is completed and track the performance of the NFT trading platform.
              </p>
              <p className="pharagraph" ><b>Branding -</b>  Branding is an important criterion for businesses to grow consistently and to reach a wider audience. We enhance you to create a strong brand that has more probability of driving success.
              </p>
            </div>
            <div className="col-lg-6 col-md-12  text-center d-none d-md-table-cell" >
                <StaticImage
                            src="https://coinsclone.mo.cloudinary.net/images/whitelabelnft/our-invaluable-nft-marketplace-services.png"
                            alt="Our Invaluable White Label NFT Marketplace Development Image"
                            width={595}
                          />
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default Invaluable